import React from "react";

import "./sidebar.css";

const Sidebar = () => {
  return (
    <div>
      <a
        href="#navbar"
        className="js-colorlib-nav-toggle colorlib-nav-toggle hidden-print"
        aria-expanded="false"
        aria-controls="navbar">
        <i></i>
      </a>
      <aside id="colorlib-aside" className="border js-fullheight hidden-print">
        <div className="text-center">
          <div
            className="author-img"
            style={{
              backgroundImage: "url(/images/logo-neu-kecke-schnecke_small.png)"
            }}
          />
          <h1 id="colorlib-logo">
            <small>Privatkindergarten</small>
            <br />
            Individuelle Kinderbetreuung
          </h1>
          <div className="position">
            <i className="icon-phone" aria-hidden="true" />
            &nbsp;<a href="tel:+436706044490">0670 / 60 444 90</a>
          </div>
        </div>
        <nav id="colorlib-main-menu" role="navigation">
          <div id="navbar">
            <ul>
              <li className="active">
                <a href="#home" data-nav-section="home">
                  Home
                </a>
              </li>
              <li>
                <a href="#about" data-nav-section="about">
                  Über uns
                </a>
              </li>
              <li>
                <a href="#food" data-nav-section="food">
                  Essen
                </a>
              </li>
              <li>
                <a href="#team" data-nav-section="team">
                  Team
                </a>
              </li>
              <li>
                <a href="#structure" data-nav-section="structure">
                  Strukturelles
                </a>
              </li>
              <li>
                <a href="#contact" data-nav-section="contact">
                  Kontakt / Impressum
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <div className="colorlib-footer">
          <p>
            <small>
              Website made with <i className="icon-heart" aria-hidden="true" /> and{" "}
              <i className="icon-coffee" aria-hidden="true" />
              <br />
              Thanks{" "}
              <a href="https://colorlib.com" target="_blank" rel="noopener noreferrer">
                Colorlib
              </a>{" "}
              for inspiration
            </small>
          </p>
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;

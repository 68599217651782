import React from "react";

import Sidebar from "./components/sidebar";
import Home from "./components/home";
import About from "./components/about";
import Food from "./components/food";
import Team from "./components/team";
import Structure from "./components/structure";
import Contact from "./components/contact";
import Imprint from "./components/imprint";

const App = () => {
  return (
    <div id="colorlib-page">
      <Sidebar />
      <main id="colorlib-main">
        <Home />
        <article>
          <About />
          <Food />
          <Team />
          <Structure />
          <Contact />
          <Imprint />
        </article>
      </main>
    </div>
  );
};

export default App;

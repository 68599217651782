import React from "react";

const Team = () => {
  return (
    <section className="colorlib-team" data-section="team">
      <div className="colorlib-narrow-content">
        <div className="row">
          <div className="col-md-12">
            <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
              <div className="col-md-12">
                <div className="about-desc">
                  <span className="heading-meta">Team</span>
                  <h2 className="colorlib-heading">Das sind wir</h2>
                  <p>
                    Wir sind ein kleines, engagiertes Team aus individuellen Persönlichkeiten,
                    welches aus zwei Pädagoginnen, zwei Betreuerinnen, einer Köchin und einer
                    Reinigungskraft besteht.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;

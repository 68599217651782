import React from "react";

import $ from "jquery";
import "./home.css";

const Home = () => {
  return (
    <section id="colorlib-hero" className="js-fullheight hidden-print" data-section="home">
      <div className="flexslider js-fullheight">
        <ul className="slides">
          <li style={{ backgroundImage: "url(/images/herbst-garten.jpg)" }}>
            <div className="overlay" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                  <div className="slider-text-inner js-fullheight">
                    <div className="desc">
                      <h1>
                        <span className="heading-section">Privatkindergarten</span>
                        <br />
                        <span className="rainbow-text">
                          Individuelle
                          <br />
                          Kinder-
                          <br />
                          betreuung
                        </span>
                      </h1>
                      <p>
                        <button
                          className="btn btn-primary btn-learn"
                          onClick={() =>
                            $("html, body").animate(
                              {
                                scrollTop: $("[data-section=contact]").offset().top - 55
                              },
                              1000
                            )
                          }>
                          Kontakt <i className="icon-home"></i>
                        </button>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li style={{ backgroundImage: "url(/images/kinder-in-karton.jpg)" }}>
            <div className="overlay" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                  <div className="slider-text-inner">
                    <div className="desc">
                      <h1>Bei uns wird viel gespielt...</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li style={{ backgroundImage: "url(/images/buben-spielen.jpg)" }}>
            <div className="overlay" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                  <div className="slider-text-inner">
                    <div className="desc">
                      <h1>...gewerkelt und gemalt...</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li style={{ backgroundImage: "url(/images/hirselaibchen-auf-teller.jpg)" }}>
            <div className="overlay" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 col-md-offset-3 col-md-pull-3 col-sm-12 col-xs-12 js-fullheight slider-text">
                  <div className="slider-text-inner">
                    <div className="desc">
                      <h1>...und gut gegessen!</h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default Home;

import React from "react";

const Imprint = () => {
  return (
    <section className="colorlib-imprint" data-section="imprint">
      <div className="colorlib-narrow-content">
        <div className="row">
          <div className="col-md-12">
            <div className="row row-bottom-padded-sm animate-box">
              <div className="col-md-12">
                <div className="contact-desc">
                  <span className="heading-meta">Impressum</span>
                  <h2 className="colorlib-heading">Für den Inhalt verantwortlich</h2>
                  <div className="col-md-6 services desc">
                    <strong>Verein für Individuelle Kinderbetreuung</strong>
                    <br />
                    Grabenstraße 20, 8010 Graz
                    <br />
                    office@individuelle-kinderbetreuung.at
                    <br />
                    +43 670 60 444 90
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Imprint;

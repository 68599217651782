import React from "react";

const Contact = () => {
  return (
    <section className="colorlib-contact" data-section="contact">
      <div className="colorlib-narrow-content">
        <div className="row">
          <div className="col-md-12">
            <div className="row row-bottom-padded-sm animate-box" data-animate-effect="fadeInLeft">
              <div className="col-md-12">
                <div className="contact-desc">
                  <span className="heading-meta">Kontakt</span>
                  <h2 className="colorlib-heading">So kann man uns erreichen</h2>
                  <p>
                    <strong>
                      Privatkindergarten
                      <br />
                      Individuelle Kinderbetreuung
                    </strong>
                  </p>
                  <p>
                    <span>
                      <i className="icon-location4" aria-hidden="true">
                        &nbsp;
                      </i>
                      <a
                        href="https://www.google.com/maps/search/?api=1&query=Privatkindergarten+Individuelle+Kinderbetreuung,+Grabenstraße+20,+8010+Graz"
                        target="_blank"
                        rel="noreferrer">
                        Grabenstraße 20, 8010 Graz
                      </a>
                    </span>
                    <br />
                    <span>
                      <i className="icon-mail6" aria-hidden="true">
                        &nbsp;
                      </i>
                      <a href="mailto:office@individuelle-kinderbetreuung.at">
                        office@individuelle-kinderbetreuung.at
                      </a>
                    </span>
                    <br />
                    <span>
                      <i className="icon-phone3" aria-hidden="true">
                        &nbsp;
                      </i>
                      <a href="tel:+436706044490">0670 / 60 444 90</a>
                    </span>
                  </p>
                  <p className="hidden-print">
                    <span>
                      <i className="icon-cloud4" aria-hidden="true">
                        &nbsp;
                      </i>
                      <a href="/kiga-ikb-contact.vcf">Visitenkarte herunterladen</a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
